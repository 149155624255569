import Ajv from 'ajv';
import get from 'lodash.get';
import {decorate, observable, computed, action} from 'mobx';
import {validatePayload, raw_vehicle_data_schema, vehicle_payload_schema} from '../utils/validation';

import "regenerator-runtime/runtime";

import copy from '../components/ride-octane-modal/copy';

export class PrequalStore {
    // references
    apiStore = null;
    userStore = null;
    uxCopyStore = null;
    themeStore = null;
    partnerStore = null;
    historyStore = null;
    dealershipStore = null;
    vehicleSelfSelectionStore = null
    waffleStore = null;
    dealerSelectorStore = null;

    // observables
    applicationUuid = null;
    primaryApplicantUuid = null;
    secondaryApplicantUuid = null;
    accessoriesAmount = null;
    vehicleConfiguration = null;
    vehicleConfigurationSlug = null;
    applicationProgress = null;

    hasFetchedVehicle = false;
    hasCompletedAboutYouForm = false;
    hasSubmittedApplication = false;
    hasCompletedDealerSelector = false;

    receivedPostMessage = false;
    widgetError = false
    isCoapplicantFlow = false;

    // data sent from Vehicle Self Selection - VSS
    rawVehicleData = null

    /**
     * True if this is a valid prequal url
     *  (e.g. has one of the following
     *      - a vehicle configuration slug
     *      - a partner and vehicle identifier
     *      - a partner and partner vehicle identifier
     *      - or none of the above with isDummyVehicleEnabled true
     */
    get isValidExperience() {
        return !!(
            this.vehicleConfigurationSlug ||
            this.partnerStore.vehicleIdentifier ||
            this.partnerStore.partnerVehicleMatch ||
            this.isDummyVehicleEnabled
        );
    }

    /**
     * True if this is a partner experience
     */
    get isPartnerExperience() {
        if (!this.isValidExperience) {
            return false;
        }
        return !!this.partnerStore.partnerIdentifier;
    }

    /**
     * True if this is a partner experience that uses a partner's vehicle identifier
     */
    get isPartnerWithVehicleIdentifierExperience() {
        if (!this.isPartnerExperience) {
            return false;
        }
        return !!this.partnerStore.vehicleIdentifier;
    }

    /**
     * True if
     *  - this is a partner experience
     *  - this is not a partner with a vehicle identifier exprience
     *  - there is a PartnerVehicleMatch
     */
    get isPartnerWithVehicleMatchExperience() {
        return (
            this.isPartnerExperience &&
            !this.isPartnerWithVehicleIdentifierExperience &&
            !!this.partnerStore.partnerVehicleMatch
        );
    }

    /**
     * True if
     *  - this is a partner experience
     *  - this is not a partner with a vehicle identifier experience
     *  - there is no vehicle configuration slug
     *  - there is no partner vehicle match id
     */
    get isPartnerDealerWidgetExperience() {
        return (
            this.isPartnerExperience &&
            !this.isPartnerWithVehicleIdentifierExperience &&
            !this.vehicleConfigurationSlug &&
            !this.partnerStore.isReveo &&
            !this.partnerStore.isRV &&
            // We check the query params cause the widgetMessageHandler can
            // also set partnerStore.partnerVehicleMatch
            !this.historyStore.queryParams.partnerVehicleMatch
        );
    }

    /**
     * True if this is a partner that allows decisioning on "dummy vehicles"
     *
     * Note: Decisioning on dummy vehicles is only used if
     * the partner enables it and there is NOT a:
     *  - vehicle slug
     *  - partner vehicle identifier
     *  - a partner vehicle match
     */
    get isDummyVehicleEnabled() {
        return get(this.partnerStore, "partner.enable_dummy_vehicle", false);
    }

    /**
     * True if the query params rawVehicleData is 1
     */
    get isVehicleSelfSelectionExperience() {
        return this.historyStore.queryParams.rawVehicleData === '1'
    }

    /**
     * Vehicle data to display. Uses data from this.partnerStore.partnerVehicleMatch if available,
     * otherwise uses data from this.vehicleConfiguration
     */
    get displayVehicle() {
        if (!this.vehicleConfiguration && !this.partnerStore.partnerVehicleMatch) {
            return null;
        }
        // initialize data with values from this.vehicleConfiguration (if it is defined, otherwise set fields to null)
        let displayData = ['uuid', 'slug', 'name', 'msrp', 'default_image', 'color'].reduce((output, key) => {
            output[key] = !!this.vehicleConfiguration ? this.vehicleConfiguration[key] : null;
            return output;
        }, {});

        // use partnerVehicleMatch for display fields (if available)
        if (this.isPartnerWithVehicleMatchExperience) {
            displayData.name = this.partnerStore.partnerVehicleMatch.model;
            displayData.msrp = this.partnerStore.partnerVehicleMatch.price;
            displayData.default_image = null;

            const partnerVehicleMatchColorIsValid = !!this.partnerStore.partnerVehicleMatch.color && this.partnerStore.partnerVehicleMatch.color !== '___';
            displayData.color = partnerVehicleMatchColorIsValid ? this.partnerStore.partnerVehicleMatch.color : null;
        }

        // return null if name or msrp are not set
        if (!displayData.name || !displayData.msrp) {
            return null;
        }

        if(this.isVehicleSelfSelectionExperience) {
            displayData.name = this.vehicleSelfSelectionStore.getVehicleName(this.rawVehicleData)
        }

        return displayData;
    }

    constructor({rideOctaneStore, apiStore, userStore, uxCopyStore, themeStore, partnerStore, historyStore, dealershipStore, vehicleSelfSelectionStore, waffleStore, dealerSelectorStore, match}) {
        // save references
        this.apiStore = apiStore;
        this.userStore = userStore;
        this.uxCopyStore = uxCopyStore;
        this.themeStore = themeStore;
        this.partnerStore = partnerStore;
        this.historyStore = historyStore;
        this.dealershipStore = dealershipStore;
        this.vehicleSelfSelectionStore = vehicleSelfSelectionStore;
        this.dealerSelectorStore = dealerSelectorStore;
        this.waffleStore = waffleStore;

        // check for vehicle configuration slug in url
        if (match.params.slug) {
            this.vehicleConfigurationSlug = match.params.slug;
        }

        // debug mode settings
        //  DEBUG_OFFERS: allows for loading of offers page with app uuid
        //  DEBUG_SSN_REQUIRED: allows for loading of ssn required page with app uuid
        const {DEBUG_OFFERS = false, DEBUG_SSN_REQUIRED = false} = rideOctaneStore.settings;
        if (DEBUG_OFFERS && match.params.subRoute === 'offers' && match.params.appUuid) {
            this.hasCompletedAboutYouForm = true;
            this.applicationUuid = match.params.appUuid;

        } else if (DEBUG_SSN_REQUIRED && match.params.subRoute === 'ssn-required' && match.params.appUuid) {
            this.hasCompletedAboutYouForm = true;
            this.applicationUuid = match.params.appUuid;

        }
    }

    get hasSubmittedSSN() {
        return get(this.applicationProgress, 'did_submit_social_security_number', false);
    }

    /*
     * Validates the vehicle payload received from the dealer cta widget
     * Returns true if the payload is correctly formatted otherwise returns false
     */
    validateVehiclePayload(data){
        const ajv = new Ajv();
        const validationResult = ajv.validate(vehicle_payload_schema, data);
        console.debug({'Validation Result': validationResult, 'errors': ajv.errors});
        return validationResult;
    }

    /*
     * Get raw vehicle data received from query params
     * Returns object if the data is correctly otherwise returns false
     */
    parseRawVehicleDataFromQueryParams(queryParams) {
        const data = {
            raw_type: queryParams.rawType,
            raw_condition: queryParams.rawCondition,
            raw_make: queryParams.rawMake || null,
            raw_model: queryParams.rawModel || null,
            raw_year: queryParams.rawYear,
            raw_price: queryParams.rawPrice
        }

        const isValid = validatePayload(raw_vehicle_data_schema, data)
        if (!isValid) {
            console.error({msg:`VSS validation payload from query params fail:[${isValid}]`, queryParams});
            return null;
        }

        const vehicleType = this.vehicleSelfSelectionStore.getVehicleCategory(data.raw_type)

        this.rawVehicleData = {
            ...data,
            raw_name: this.vehicleSelfSelectionStore.getVehicleName(data),
            raw_msrp: data.raw_price,
            raw_year: parseInt(data.raw_year),
            raw_price: parseInt(data.raw_price),
            raw_category: vehicleType.name,
            raw_icon: vehicleType.icon
        }

        return this.rawVehicleData
    }

    /**
     * Message handler for dealer cta widget
     * The handler takes in a vehicle payload, sets the dealership, and fetches the vehicle configuration
     * To guard against malicious messages, we have to validate the message's origin and data first
     * @param {MessageEvent<any>} event
     */
    async widgetMessageHandler(event) {
        const valid = this.validateVehiclePayload(event.data);
        if (!valid) {
            return;
        }
        if (this.receivedPostMessage && this.partnerStore.rawVehicle === event.data) {
            // duplicate message
            return;
        }
        const isTrusted = await this.dealershipStore.isTrustedUrl(event.origin);
        if (!isTrusted) {
            return; // Message origin did not match any dealerships
        }

        this.partnerStore.rawVehicle = event.data;
        if (event.data.product_origin_url) {
            this.partnerStore.partnerOriginUrl = event.data.product_origin_url;
        }
        if (event.data.product_id) {
            this.partnerStore.productId = event.data.product_id;
        }
        if (event.data.product_location) {
            this.partnerStore.productLocation = event.data.product_location;
        }
        this.receivedPostMessage = true;

        const match_params = {
            make: event.data.product_make,
            model: event.data.product_model,
            year: event.data.product_year,
            price: event.data.product_price,
            color: event.data.product_color,
            condition: event.data.product_condition,
        };

        // override data based on flag rawVehicleData
        if (this.historyStore.queryParams.rawVehicleData === '1') {
            const rawVehicleData = this.parseRawVehicleDataFromQueryParams(this.historyStore.queryParams);
            if (!rawVehicleData) return;

            match_params.make = rawVehicleData.raw_make;
            match_params.model = rawVehicleData.raw_model;
            match_params.year = rawVehicleData.raw_year;
            match_params.price = rawVehicleData.raw_price;
            match_params.condition = rawVehicleData.raw_condition;

            Object.assign(this.partnerStore.rawVehicle, {
                product_category: rawVehicleData.raw_type,
                product_condition: rawVehicleData.raw_condition,
                product_make: rawVehicleData.raw_make,
                product_model: rawVehicleData.raw_model,
                product_year: rawVehicleData.raw_year,
                product_price: rawVehicleData.raw_price,
            });
            console.debug('[rawVehicleData] using raw vehicle query params:', rawVehicleData);
        }

        if (this.partnerStore.dealerRoutingStrategy === 'WidgetDealerRoutingStrategy') {
            await this.dealershipStore.fetchDealerGroupRouting(this.partnerStore.partnerIdentifier, this.partnerStore.rawVehicle.product_location, event.origin);
        }

        console.log('[widgetMessageHandler] match_params:', match_params);
        this.partnerStore.loadPartnerVehicleMatch(match_params).then(() => {
            return Promise.resolve(this.initializeVehicleConfiguration());
        });
    }

    /* Adds event listener to handle postMessage from the dealer cta widget
     */
    startWidgetMessageListener(window) {
        // check every 300ms for 20 times ( total of 6 seconds ) to see if event is
        // null, and if so, then set widgetError.
        // After 6 seconds, it clears the interval.
        let timeoutCounter = 0;
        const maxTimeoutAttempts = 20;
        var checkForEvent = setInterval((event) => {
            if(this.receivedPostMessage){
                clearInterval(checkForEvent);
            }
            else{
                if(timeoutCounter>=maxTimeoutAttempts){
                    console.error('Timed out waiting for vehicle message');
                    this.widgetError = true;
                    clearInterval(checkForEvent);
                }
                if(event == null){
                    timeoutCounter += 1;
                }
            }
        }, 300);
        window.addEventListener('message', (event) => {this.widgetMessageHandler(event)});
    }

    /**
     * Determines what the vehicle configuration should be and updates it
     *  - Vehicle configuration is fetched if:
     *      - There is a slug
     *      - There is no slug and this is a partner vehicle identifier experience
     *  - Vehicle configuration comes from the partner vehicle match if:
     *      - There is no slug and there is a partnerVehicleMatch
     *  - Vehicle configuration is null if the above are false
     */
    async initializeVehicleConfiguration() {
        let vehicleConfiguration = null;
        
        if (this.vehicleConfigurationSlug || this.isPartnerWithVehicleIdentifierExperience) {
            vehicleConfiguration = await this.fetchVehicleConfiguration();
        }
        else if (this.isPartnerWithVehicleMatchExperience) {
            vehicleConfiguration = this.partnerStore.partnerVehicleMatch.matched_vehicle;
        }

        // if it's a VSS experience save data comes from query params in rawVehicleData
        if(this.isVehicleSelfSelectionExperience) {
            const rawVehicleData = this.parseRawVehicleDataFromQueryParams(this.historyStore.queryParams);
            if (!rawVehicleData) return;

            vehicleConfiguration = Object.assign(vehicleConfiguration || {}, {
                name: rawVehicleData.raw_name,
                msrp: rawVehicleData.raw_msrp,
            })

            // when url has partner identifier as query param
            if(this.partnerStore.partnerIdentifier) {
                const conditionParsed = rawVehicleData.raw_condition === 'N' ? 'New' : 'Used'
                this.partnerStore.rawVehicle = {
                    product_category: rawVehicleData.raw_type,
                    product_condition: conditionParsed,
                    product_make: rawVehicleData.raw_make,
                    product_model: rawVehicleData.raw_model,
                    product_year: rawVehicleData.raw_year,
                    product_price: rawVehicleData.raw_price,
                }
                console.debug(`update rawVehicle data for Partner [${this.partnerStore.partnerIdentifier}] ${this.partnerStore.rawVehicle}`)
            }
            
        }

        this.updateVehicleConfiguration({vehicleConfiguration});
    }

    /**
     * Attempts to fetch the vehicle configuration
     */
    fetchVehicleConfiguration() {
        const fields = ['uuid', 'slug', 'name', 'msrp', 'default_image', 'color'];

        // vehicle configuration endpoint
        let endpoint;

        if (this.isPartnerExperience){
            endpoint = `partners/${this.partnerStore.partnerIdentifier}/configurations/`;

            if (this.isPartnerWithVehicleIdentifierExperience) {
                // fetch vehicle configuration by partner vehicle identifier
                endpoint += this.partnerStore.vehicleIdentifier.toUpperCase();
            }
            else {
                // fetch vehicle configuration by slug
                endpoint += this.vehicleConfigurationSlug;
            }
        }
        else {
            // fetch vehicle configuration by slug
            endpoint = `configurations/${this.vehicleConfigurationSlug}`;
        }

        // perform fetch
        return this.apiStore.fetch(endpoint, {query: {fields}})
            .then(({status, response}) => {
                if (status !== 200 || !response) {
                    console.error('Failed to fetch vehicle configuration', {status, response});
                    return null;
                }
                return response;
            })
            .catch(error => {
                console.error('Failed to fetch vehicle configuration', error);
                return null;
            });
    }

    //fetch progress
    refreshApplicationProgress() {
        return Promise.all([
            this.apiStore.fetch(`applications/${this.applicationUuid}/progress`),
        ]).then(([applicationProgressResponse]) => {
            let hasError = false;
            if (applicationProgressResponse.status !== 200 || !applicationProgressResponse.response) {
                hasError = true;
                console.error('Failed to fetch application Progress', applicationProgressResponse);
            }
            if (hasError) {
                return null;
            }
            return applicationProgressResponse.response;
        }).catch(error => {
            console.error('Failed to fetch application progress', error);
            return null;
        }).then(applicationProgress => {
            return this.updateApplicationProgress({applicationProgress});
        });
    }

    updateApplicationProgress({applicationProgress}) {
        this.applicationProgress = applicationProgress;
        return this;
    }

    addApplicantUserInfo(data, prefix = "") {
        const today = new Date(Date.now());
        const _userInfo = this.userStore.userInfo;
        const months_at_residence = (
            ((today.getFullYear() - parseInt(_userInfo[prefix + "residential_date"].year)) * 12) +
            ((today.getMonth() - parseInt(_userInfo[prefix + "residential_date"].month)) + 1)
        );

        data[prefix + "first_name"] = _userInfo[prefix + "first_name"];
        data[prefix + "last_name"] = _userInfo[prefix + "last_name"];
        data[prefix + "dob"] = [
            `${_userInfo[prefix + "date_of_birth"].month}`.padStart(2, 0),
            `${_userInfo[prefix + "date_of_birth"].day}`.padStart(2, 0),
            `${_userInfo[prefix + "date_of_birth"].year}`
        ].join('/');
        data[prefix + "email"] = _userInfo[prefix + "email"];
        data[prefix + "street1"] = _userInfo[prefix + "street1"];
        data[prefix + "city"] = _userInfo[prefix + "city"];
        data[prefix + "state"] = _userInfo[prefix + "state"];
        data[prefix + "zip_code"] = _userInfo[prefix + "zip_code"];
        data[prefix + "residential_status"] = _userInfo[prefix + "residential_status"];
        data[prefix + "residential_months"] = months_at_residence;
        data[prefix + "gross_monthly_income"] = Math.floor(_userInfo[prefix + "yearly_income"]/12);
        data[prefix + "employment_status"] = _userInfo[prefix + "employment_status"];
        data[prefix + "phone_number"] = _userInfo[prefix + "phone_number"];

        if (prefix === "") {
            data.purchase_intent = _userInfo.purchase_intent;
            data.contact_by_phone = this.userStore.contactPreferences.contact_by_phone;
            data.contact_by_text = this.userStore.contactPreferences.contact_by_text;
            data.contact_by_email = this.userStore.contactPreferences.contact_by_email;
        } else {
            data.relationship_to_primary = _userInfo.relationship_to_primary;
        }

        // only add coapplicant street 2 if there is a non empty value
        if(_userInfo[prefix + "street2"]) {
            data[prefix + "street2"] = _userInfo[prefix + "street2"];
        }

        // add partner_conset from userStore
        if (this.partnerStore.partner && this.partnerStore.partner.show_about_you_consent_text) {
            data.partner_consent = this.userStore.partner_consent === true;
        }        

        return data;
    }

    async submitApplication(captcha) {
        let data = {
            // other data
            captcha: captcha,
            accessories_cost: this.accessoriesAmount,
            meta_data: {},
        };

        // add primary applicant user info to data
        data = this.addApplicantUserInfo(data, "");

        // add coapplicant user info to data if coapplicant dtc is enabled in config and coapplicant_intent is checked
        if(this.partnerStore.isCoapplicantEnabled && this.userStore.userInfo.coapplicant_intent) {
            data = this.addApplicantUserInfo(data, "coapplicant_");
            data.ssn = this.userStore.userInfo.ssn;
            data.coapplicant_ssn = this.userStore.userInfo.coapplicant_ssn;
        }

        // add disclosure text to data
        for (let disclosure of ['e_sign_act_disclosure', 'general_application_disclosure','coppa_disclosure', 'patriot_act_disclosure', 'alternate_lenders_disclosure']) {
            let copyKey = `${disclosure}.markdown_content`;
            data[disclosure] =  this.uxCopyStore.getRideOctaneModalCopy(copyKey) || get(copy, copyKey) || "";
        }
       
        // only add vehicle if there is a vehicle configuration
        if (this.vehicleConfiguration && this.vehicleConfiguration.uuid) {
            data.vehicle = this.vehicleConfiguration.uuid;
        }

        // only add dealership if the query param is present
        if (this.partnerStore.dealerRoutingStrategy === 'WidgetDealerRoutingStrategy' && this.dealershipStore.dealershipId) {
            data.consumer_dealership_identifier = this.dealershipStore.dealershipId;
            data.dealership = this.dealershipStore.dealershipId;
        } else if (this.historyStore.queryParams.dealership) {
            data.consumer_dealership_identifier = this.historyStore.queryParams.dealership;
            data.dealership = this.historyStore.queryParams.dealership;
        }

        // only add appTheme if there is an applied theme
        if (this.themeStore.theme) {
            data.meta_data.appTheme = this.themeStore.theme;
        }

        data = this.addPartnerSpecificApplicationData(data);

        // only add this if it's not a PartnerExperience and it's a VSS experience
        if(this.isVehicleSelfSelectionExperience && !this.isPartnerDealerWidgetExperience) {
            data.raw_vehicle = this.rawVehicleData
        }

        const isDealerSelectorEnabled = await this.isDealerSelectorEnabled()
        if (isDealerSelectorEnabled && !this.hasCompletedDealerSelector) {
            this.historyStore.history.push(
                this.getPrequalUrl(`/dealer`)
            );
            return Promise.reject({
                ignore: true,
                msg:'redirecting to dealer selector screen'
            });
        }

        // Try to POST the application to the API
        try {
            let endpoint = "applications"; // POST application endpoint

            await this.partnerStore.postPartnerVehicleMatch();

            // Add partnerVehicleMatchId to the application data if available
            if (this.partnerStore.partnerVehicleMatchId) {
                data.partner_vehicle_match = this.partnerStore.partnerVehicleMatchId;
            }

            if (this.isPartnerExperience) {
                // Add externalDealershipId to the application data if available
                if (this.dealershipStore.externalDealershipId) {
                    data.external_dealership_id = this.dealershipStore.externalDealershipId;
                }

                endpoint = `partners/${this.partnerStore.partnerIdentifier}/applications`;
            }

            // Perform POST
            let {status, response} = await this.apiStore.fetch(endpoint, {
                method: "POST",
                data,
            });

            // If the status isn't 200 or there isn't an application_uuid, throw an error
            if (status !== 200 || !response || !response.application_uuid) {
                throw {status, response};
            }

            this.updateApplicationUuid({applicationUuid: response.application_uuid});
            this.updateApplicantUuid({applicantUuid: response.primary_applicant_uuid, isSecondaryApplicantUuid: false});
            if(response.secondary_applicant_uuid) {
                this.updateApplicantUuid({applicantUuid: response.secondary_applicant_uuid, isSecondaryApplicantUuid: true});
            }

            // Poll our application progress endpoint
            await this.refreshApplicationProgress();
        } catch (error) {
            console.error("Failed to submit application", error);
        }

        this.hasSubmittedApplication = true;

        return this;
    }

    addPartnerSpecificApplicationData(data) {
        // add partnerLeadId to meta_data if this is Reveo and it exists
        if (this.partnerStore.isReveo) {
            if (this.partnerStore.partnerLeadId) {
                data.meta_data.partnerLeadId = this.partnerStore.partnerLeadId;
            } else {
                console.error('Submitting Reveo application without partnerLeadID')
            }
        }

        /**
         * We should expect trade_in_intent and coapplicant_intent values
         * for RV partner experiences as well as whenever coapplicant is enabled
         * for the partner experience.
         */
        if (this.partnerStore.isRV || this.partnerStore.isCoapplicantEnabled) {
            data.trade_in_intent = this.userStore.userInfo.trade_in_intent === "Yes";
            data.coapplicant_intent = !!this.userStore.userInfo.coapplicant_intent;
        }

        /**
         * Add partner origin URL if visiting from a dealer widget
         */
        if (this.partnerStore.partnerOriginUrl) {
            data.meta_data.partnerOriginUrl = this.partnerStore.partnerOriginUrl;
        }

        /**
         * N2O-2116: If we have a location for the product, include it in the metadata
         */
        if (this.partnerStore.productLocation) {
            data.meta_data.productLocation = this.partnerStore.productLocation;
        }

        /**
         * N2O-1621 Used for CycleTrader's AdId, but can be generic as well
         */
        if (this.partnerStore.productId) {
            data.meta_data.externalProductId = this.partnerStore.productId;
        }

        return data;
    }

    onSubmitAboutYouForm = captcha => {
        this.hasCompletedAboutYouForm = true;
        this.applicationUuid = null;
        this.primaryApplicantUuid = null;
        this.submitApplication(captcha).then(() => {
            if(this.partnerStore.isCoapplicantEnabled) {
                this.historyStore.history.push(
                    this.getPrequalUrl(`/offers/${this.applicationUuid}/${this.primaryApplicantUuid}`)
                );
            }
            else {
                this.historyStore.history.push(
                    this.getPrequalUrl(`/offers/${this.applicationUuid}`)
                );
            }
        }).catch(error => {
            // use to ignore errors, used for redirecting to dealer selector screen
            if (error.ignore) return

            console.error('Failed to submit application', {
                error,
                metadata: {
                    isPartnerExperience: this.isPartnerExperience,
                    dealership: this.dealershipStore.dealershipId,
                    partner: this.partnerStore.partnerIdentifier
                }
            });
        });

        return this;
    };

    onContinueToCoapplicant = () => {
        this.isCoapplicantFlow = true;
        this.historyStore.history.push(
            //Since the application is not submitted yet, this url will not have an applicationUuid
            this.getPrequalUrl(`/coapplicant`)
        );
        return this;
    }

    submitSSN(ssn) {
        const data = {
            social_security_number: ssn
        };
        // application endpoint
        let endpoint = 'applications';
        if (this.isPartnerExperience) {
            endpoint = `partners/${this.partnerStore.partnerIdentifier}/applications`;
        }
        endpoint += `/${this.applicationUuid}/ssn`;

        return this.apiStore.fetch(endpoint, {method: 'PUT', data})
            .then(({status, response}) => {
                if (status !== 200 || !response || !response.application_uuid) {
                    console.error('Failed to submit ssn for application', {status, response});
                }
                return null;
            })
            .catch(error => {
                console.error('Failed to submit application', error);
                return null;
            }).then(() => {
                return this.refreshApplicationProgress();
            }).then(()=> {
                return this;
            });
    };

    onSubmitSSN = (ssn) => {
       this.submitSSN(ssn).then(() => {
           this.historyStore.history.push(
               this.getPrequalUrl(`/offers/${this.applicationUuid}`)
           );
       });
       return this;
    };

    updateApplicationUuid({applicationUuid}) {
        if (applicationUuid) {
            this.applicationUuid = applicationUuid;
        }

        return this;
    }

    updateApplicantUuid({applicantUuid, isSecondaryApplicantUuid}) {
        if (applicantUuid) {
            if (!isSecondaryApplicantUuid) {
                this.primaryApplicantUuid = applicantUuid;
            }
            else {
                this.secondaryApplicantUuid = applicantUuid;
            }
        }
        return this;
    }

    /**
     * Updates vehicleConfiguration and the following observables
     *  - vehicleConfigurationSlug (if this is a partner experience with vehicle id or vehicle match)
     *  - accessoriesAmount
     *  - hasFetchedVehicle
     * @param {object} vehicleConfiguration
     */
    updateVehicleConfiguration({vehicleConfiguration}) {
        const {msrp, vehicleDisplayName, accessoriesAmount} = this.historyStore.queryParams;

        this.hasFetchedVehicle = true;
        this.accessoriesAmount = parseFloat(accessoriesAmount) || 0;
        if (vehicleConfiguration) {
            this.vehicleConfiguration = vehicleConfiguration;

            if (this.isPartnerExperience) {

                // override name
                if (vehicleDisplayName) {
                    this.vehicleConfiguration.name = vehicleDisplayName;
                }

                // log error if msrp doesn't match
                if (msrp && (parseFloat(msrp) !== parseFloat(this.vehicleConfiguration.msrp))) {
                    console.error('Supplied msrp and our msrp are not equal');
                }

                // set vehicleConfigurationSlug if fetched using partner vehicle identifier or vehicle match
                if (this.isPartnerWithVehicleIdentifierExperience || this.isPartnerWithVehicleMatchExperience) {
                    this.vehicleConfigurationSlug = vehicleConfiguration.slug;
                }
            }
        }

        return this;
    }

    /**
     * Helper - Returns url string for the specified subRoute
     *
     * @param {string} [subRoute]
     *  (e.g offers...)
     */
    getPrequalUrl(subRoute = '') {
        if (this.isPartnerWithVehicleIdentifierExperience || this.isPartnerWithVehicleMatchExperience || this.isVehicleSelfSelectionExperience) {
            return "/apply" + subRoute;
        }

        // If there is no vehicle and "dummy vehicle" is enabled, don't add a slug
        if (!this.vehicleConfiguration && this.isDummyVehicleEnabled) {
            return "/apply" + subRoute;
        }

        return `/${this.vehicleConfigurationSlug}/apply` + subRoute;
    }
    
    onSubmitDealership = (dealershipId) => {
        this.hasCompletedDealerSelector = true;
        this.dealershipStore.updateStore({dealership:{
            dealership_identifier: dealershipId
        }})
        this.onSubmitAboutYouForm();
    }

    /**
     * True if this is a partner that allows show Dealer Selector screen
     * when there is not dealership associated
     */
    async isDealerSelectorEnabled() {
        return await this.dealerSelectorStore.isDealerSelectorEnabled()
    }
}

decorate(PrequalStore, {
    applicationUuid: observable,
    primaryApplicantUuid: observable,
    secondaryApplicantUuid: observable,
    accessoriesAmount: observable,
    vehicleConfiguration: observable,
    vehicleConfigurationSlug: observable,

    hasFetchedVehicle: observable,
    hasCompletedAboutYouForm: observable,
    hasSubmittedApplication: observable,
    applicationProgress: observable,
    widgetError: observable,
    isCoapplicantFlow: observable,

    isValidExperience: computed,
    isPartnerExperience: computed,
    isPartnerWithVehicleIdentifierExperience: computed,
    isPartnerDealerWidgetExperience: computed,
    displayVehicle: computed,
    hasSubmittedSSN: computed,
    isDummyVehicleEnabled: computed,

    onSubmitAboutYouForm: action,
    onContinueToCoapplicant: action,
    onSubmitContactPreferencesForm: action,
    onSubmitSSN: action,

    updateApplicationUuid: action,
    updateApplicantUuid: action,
    updateVehicleConfiguration: action,
    updateApplicationProgress: action,
    isDealerSelectorEnabled: action,
});
