import classNames from "classnames";
import React from "react";
import { observer } from "mobx-react";
import { Col, Container, Row } from "react-bootstrap";

import { FormHeader } from "../../../components/form-header";
import copy from "./copy";

import {
   CityField,
   ContactPreferenceField,
   DateOfBirthField,
   EmailField,
   EmployerPhoneNumberField,
   EmploymentStatusField,
   FirstNameField,
   LastNameField,
   PurchaseIntentField,
   RelationshipToPrimaryField,
   ResidentialDateField,
   ResidentialStatusField,
   ReusePrimaryApplicantAddressField,
   StateField,
   Street1Field,
   Street2Field,
   YearlyIncomeField,
   ZipcodeField,
   CoapplicantIntentField,
   SubmitFormButton,
} from "../../../form-fields";
import { DisclosureBlock } from "../../../components/disclosure-block";
import { BrandingValueProp } from "../../../components/branding";
import { Captcha } from "../../../components/captcha";

const AboutYouStep = ({
   isCoapplicantFlow,
   accessoriesAmount,
   vehicleConfiguration,
   rawVehicleData,
   hasFetchedVehicle,
   store,
   flowStore,
   partnerStore,
   captchaRef,
   launchCaptcha,
}) => {
   return (
      <Container data-oid="two-step-prequal-container" key="primary_applicant_flow">
         <div className={classNames("w-450px mw-100 mx-auto")}>
            <FormHeader
               headerType={store.headerType}
               vehicleConfiguration={vehicleConfiguration}
               accessoriesAmount={accessoriesAmount}
               title={copy.title}
               isCoapplicantFlow={isCoapplicantFlow}
            />

            <form id="two-step-prequal-form">
               {isCoapplicantFlow && (
                  <RelationshipToPrimaryField
                     controlId={"relationship_to_primary"}
                     value={store.fieldStates["relationship_to_primary"].value}
                     error={store.fieldStates["relationship_to_primary"].error}
                     onChange={(event) => store.updateFieldValue(event, "relationship_to_primary")}
                  />
               )}

               <Row>
                  <Col xs={6}>
                     <FirstNameField
                        controlId={"first_name"}
                        value={store.fieldStates["first_name"].value}
                        error={store.fieldStates["first_name"].error}
                        onChange={(event) => store.updateFieldValue(event, "first_name")}
                     />
                  </Col>
                  <Col xs={6}>
                     <LastNameField
                        controlId={"last_name"}
                        value={store.fieldStates["last_name"].value}
                        error={store.fieldStates["last_name"].error}
                        onChange={(event) => store.updateFieldValue(event, "last_name")}
                     />
                  </Col>
               </Row>

               <EmailField
                  controlId={"email"}
                  value={store.fieldStates["email"].value}
                  error={store.fieldStates["email"].error}
                  onChange={(event) => store.updateFieldValue(event, "email")}
               />

               <DateOfBirthField
                  controlId={"date_of_birth"}
                  value={store.fieldStates["date_of_birth"].value}
                  error={store.fieldStates["date_of_birth"].error}
                  onChange={(event) => store.updateFieldValue(event, "date_of_birth")}
               />

               {isCoapplicantFlow && store.primaryApplicantAddressData && (
                  <ReusePrimaryApplicantAddressField
                     controlId={"reuse_primary_address"}
                     value={store.fieldStates["reuse_primary_address"].value}
                     error={store.fieldStates["reuse_primary_address"].error}
                     onChange={(event) => store.onReusePrimaryAddressChange(event)}
                  />
               )}

               <Street1Field
                  controlId={"street1"}
                  value={store.fieldStates["street1"].value}
                  error={store.fieldStates["street1"].error}
                  onChange={(event) => store.updateFieldValue(event, "street1")}
                  required={!store.isReusingPrimaryAddress}
                  disabled={store.isReusingPrimaryAddress}
               />

               <Street2Field
                  controlId={"street2"}
                  value={store.fieldStates["street2"].value}
                  error={store.fieldStates["street2"].error}
                  onChange={(event) => store.updateFieldValue(event, "street2")}
                  disabled={store.isReusingPrimaryAddress}
               />

               <CityField
                  controlId={"city"}
                  value={store.fieldStates["city"].value}
                  error={store.fieldStates["city"].error}
                  onChange={(event) => store.updateFieldValue(event, "city")}
                  required={!store.isReusingPrimaryAddress}
                  disabled={store.isReusingPrimaryAddress}
               />

               <Row>
                  <Col xs={6}>
                     <StateField
                        controlId={"state"}
                        error={store.fieldStates["state"].error}
                        value={store.fieldStates["state"].value}
                        onChange={(event) => store.updateFieldValue(event, "state")}
                        required={!store.isReusingPrimaryAddress}
                        disabled={store.isReusingPrimaryAddress}
                     />
                  </Col>
                  <Col xs={6}>
                     <ZipcodeField
                        controlId={"zip_code"}
                        value={store.fieldStates["zip_code"].value}
                        error={store.fieldStates["zip_code"].error}
                        onChange={(event) => store.updateFieldValue(event, "zip_code")}
                        required={!store.isReusingPrimaryAddress}
                        disabled={store.isReusingPrimaryAddress}
                     />
                  </Col>
               </Row>

               <ResidentialStatusField
                  controlId={"residential_status"}
                  value={store.fieldStates["residential_status"].value}
                  error={store.fieldStates["residential_status"].error}
                  onChange={(event) => store.updateFieldValue(event, "residential_status")}
               />

               <ResidentialDateField
                  controlId={"residential_date"}
                  value={store.fieldStates["residential_date"].value}
                  error={store.fieldStates["residential_date"].error}
                  onChange={(event) => store.updateFieldValue(event, "residential_date")}
               />

               <EmploymentStatusField
                  controlId={"employment_status"}
                  value={store.fieldStates["employment_status"].value}
                  error={store.fieldStates["employment_status"].error}
                  onChange={(event) => store.updateFieldValue(event, "employment_status")}
               />

               <YearlyIncomeField
                  controlId={"yearly_income"}
                  value={store.fieldStates["yearly_income"].value}
                  error={store.fieldStates["yearly_income"].error}
                  onChange={(event) => store.updateFieldValue(event, "yearly_income")}
                  toolTipText={"Note"}
                  toolTipMsg={
                     "Alimony, child, support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation or for account maintenance."
                  }
               />

               {!isCoapplicantFlow && (
                  <ContactPreferenceField
                     controlId={"contact_preference"}
                     value={store.fieldStates["contact_preference"].value}
                     error={store.fieldStates["contact_preference"].error}
                     onChange={(event) => store.updateFieldValue(event, "contact_preference")}
                     partnerStore={store.partnerStore}
                  />
               )}

               <EmployerPhoneNumberField
                  controlId={"phone_number"}
                  value={store.fieldStates["phone_number"].value}
                  error={store.fieldStates["phone_number"].error}
                  onChange={(event) => store.updateFieldValue(event, "phone_number")}
               />

               {!isCoapplicantFlow && (
                  <PurchaseIntentField
                     controlId={"purchase_intent"}
                     value={store.fieldStates["purchase_intent"].value}
                     error={store.fieldStates["purchase_intent"].error}
                     onChange={(event) => store.updateFieldValue(event, "purchase_intent")}
                     partnerStore={store.partnerStore}
                     apiStore={store.apiStore}
                  />
               )}

               {store.showCoapplicantIntentField && (
                  <CoapplicantIntentField
                     controlId={"coapplicant_intent"}
                     value={store.fieldStates["coapplicant_intent"].value}
                     error={store.fieldStates["coapplicant_intent"].error}
                     onChange={(event) => store.updateFieldValue(event, "coapplicant_intent")}
                  />
               )}

               {/* DISCLOSURES AND CAPTCHA */}
               <div>
                  <BrandingValueProp
                     showPoweredByOctane={store.showPoweredByOctane}
                     showDesktopLayout={!!partnerStore.hasMultiColumnDisplay}
                     showBrandingForCampingWorld={partnerStore.isRV}
                     showMiniProp
                  />

                  <DisclosureBlock
                     id="consent"
                     showCheckBox
                     showRuleLine
                     disclosureText={store.prequalDisclosure}
                     onChange={store.updatePrequalDisclosureConsent}
                     isChecked={store.prequalDisclosureConsent}
                  />
                  <DisclosureBlock
                     id="text-consent"
                     showCheckBox
                     disclosureText={copy.text_consent.labelContent}
                     onChange={store.updateTextConsent}
                     isChecked={store.textConsent}
                  />

                  <Captcha
                     captchaRef={captchaRef}
                     onClickContinue={(captcha) =>
                        store.submitForm(
                           captcha,
                           vehicleConfiguration,
                           rawVehicleData,
                           flowStore.updateApplicationUuid,
                           flowStore.updateApplicantUuids
                        )
                     }
                  />

                  <SubmitFormButton
                     canContinue={
                        hasFetchedVehicle &&
                        store.prequalDisclosureConsent &&
                        store.isFormValid &&
                        store.textConsent
                     }
                     oid="cta-two-step-prequal"
                     onClick={launchCaptcha}
                     continueButtonLabel={store.continueButtonLabel}
                     partnerStore={store.partnerStore}
                  />
               </div>
            </form>
         </div>
      </Container>
   );
};

export default observer(AboutYouStep);
